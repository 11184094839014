import React, { createContext, useContext, useState, useEffect } from "react";

// 
const CartContext = createContext();


export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")));


  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart"));
    console.log(storedCart);
    setCart(storedCart);

  }, []);


  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);


  const addCardToCart = (newCard) => {
    setCart((prevCart) => {
      const existingCard = prevCart.find((item) => item.id === newCard.id);
      if (existingCard) {

        return prevCart.map((item) =>
          item.id === newCard.id
            ? { ...item, quantity: item.quantity + newCard.quantity }
            : item
        );
      } else {
        return [...prevCart, newCard];
      }
    });
  };

  const updateCartItem = (updatedCard) => {
    // console.log(updatedCard);

    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === updatedCard.id
          ? { ...item, quantity: updatedCard.quantity }
          : item
      )
    );
  };


  const removeFromCart = (id) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));
  };
  return (
    <CartContext.Provider value={{ cart, addCardToCart, updateCartItem, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};


export const useCart = () => {
  return useContext(CartContext);
};
